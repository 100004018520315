const servicesapi = [
    {
        id: "1",
        title: "our services",
        subtitle: "We are the one of the most effective Web Design Companies",
        info : "At Internet Business Hub, we are committed to understanding your unique needs and providing customized solutions that drive success. Whether you're a startup looking to establish your online presence or an established business seeking to enhance your digital strategy, we are here to help you achieve your goals.",

    }
]

export default servicesapi