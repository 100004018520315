//import logo from './logo.svg';
import './App.css';
import './css/bootstrap.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Notfound from './components/Notfound';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path='*' element={<Notfound />} />
      </Routes>
    </Router>
  )
}

export default App;
   
