import React, { useState } from 'react'
import aboutImage from '../img/about.webp';
import dotsImage from '../img/dots.png';
import aboutapi from '../Api/aboutapi';
import serviceapi from '../Api/serviceapi';
import homesectionapi from '../Api/homesectionapi';
import servicesapi from '../Api/servicesapi';
import contactapi from '../Api/contactapi';
const Homesections = () => {
    const [aboutusData, setUserData] = useState(aboutapi);
    const [servicesData, SetUserData] = useState(serviceapi);
    const [homesectionData, SetUserdata] = useState(homesectionapi);
    const [serviceData, setuserdata] = useState(servicesapi);
    const [contactData, setUserdata] = useState(contactapi);
    return (
        <>
        <div id="home" className="bg-home-section">
            <div className="container pt-5 pb-5">
                <div className="row">
                    <div className="p-r col-md-12 text-center col-12 mb-3">
                    { homesectionData.map((curElem) => { const {id, heading, subheading, info } = curElem ;
                        return (
                        <>
                        <div className="mx-auto text-center">
                            <h6 className="text-uppercase text-pink">{heading}</h6>
                            <span className="mx-auto border-btm mb-3"></span>
                            <h1 className="fsize4 text-white mb-4">{subheading}</h1>
                            <h4 className="line-1 anim-typewriter text-white">
                                {info}
                            </h4> 
                            
                        </div>     
                        </>
                       ) 
                    })}
                           
                    </div>
                </div>
            </div>
        </div>

        <div id="about" className="pt-5 mt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex justify-content-center align-items-center col-12 mb-5">
                        <div className="bg-white p-3 shadow rounded">
                            <img src={aboutImage} alt="Internet Business Hub" className="rounded img-fluid" />   
                        </div>    
                    </div>
                    

                    { aboutusData.map((curElem) => { const {id, subtitle, title, info, headingmission, descmission, headingvision, descvision, headingvalue, descvalue } = curElem ;
                        return (
                        <>
                        <div className="col-md-6 col-12">
                            <h6 className="text-uppercase text-pink">{title}</h6>
                            <span className="border-btm mb-3"></span>

                            <h3 className="mb-4">{subtitle}</h3>
                            <p>{info}</p>

                            <ul className="list-unstyled">
                                    
                                <li> 
                                    <h5>{headingmission}</h5>
                                    <p>{descmission}</p>    
                                </li>
                                <li> 
                                    <h5>{headingvision}</h5>
                                    <p>{descvision}</p>    
                                </li>
                                <li> 
                                    <h5>{headingvalue}</h5>
                                    <p>{descvalue}</p>    
                                </li>
                            
                            </ul>
                        </div>
                    </>
                    ) 
                })}
                    
                </div>     
                  
            </div>
        </div>


        <div id="services" className="pt-5 bg-light services pb-5">
            <div className="container">
                {serviceData.map((curElem) => { const 
                    {id, title, subtitle, info} = curElem;
                    return(
                        <>
                            <div className="mx-auto col-md-8 col-12 mb-5 text-center">
                                <div className="mx-auto">
                                    <h6 className="text-uppercase text-pink">{title}</h6>
                                    <label className="border-btm mx-auto mb-3"></label>
                                </div>    

                                <h3 className="mb-4">{subtitle}</h3>
                                <p>{info}</p>
                            </div>
                        </>
                    )
                
                })}
                 

                <div className="row">
                            {servicesData.map((curElem) => {
                            const {id, icon, title, info} = curElem;            
                            return( 
                                <>
                                    <div className="col-md-4  text-center col-12 mb-4">
                                        <div className="border position-relative shadow-sm bg-white rounded p-5">
                                            <div className="border-design">
                                                <img src={dotsImage} alt="Internet Business Hub" className="rounded img-fluid" />
                                            </div>
                                            <span className="shadow-sm border">
                                                <i className={icon}> </i>
                                            </span>
                                            <h4>{title}</h4>
                                            <p>{info}</p>
                                        </div>    
                                    </div> 
                                    </>  
                                )
                            })} 

                    


                </div>   
            </div>
        </div> 




        <div id="contact" className="pt-5 pb-5">
            <div className="container">

                {contactData.map((curElem) => {
                    const {id, title, subtitle, heading, email, phone, address, businessheading, text1, text2, text3 } = curElem;
                    return (
                        <>
                            <div className="mx-auto col-md-10 col-12 mb-5">
                                <div className="mx-auto text-center">
                                    <h6 className="text-uppercase text-pink">{title}</h6>
                                    <label className="border-btm mx-auto mb-3"></label>
                                    <h3 className="text-center">{subtitle}</h3>
                                </div> 
                    

                                <div className="row mt-5">
                                    <div className="col-md-6 col-12">
                                    <h5 className="mb-3">{heading}</h5>
                                            <ul className="mb-4 contact-list list-unstyled">
                                                <li> 
                                                    <i className="fa-regular text-pink fa-envelope fa-fw"></i> 
                                                    <a className="text-dark text-decoration-none" href="mailto:hello@internetbusinesshub.com"> {email}</a> 
                                                </li>
                                                <li> 
                                                    <i className="fa-regular text-pink fa-phone fa-fw"></i> 
                                                    <a className="text-dark text-decoration-none" href="tel:8283874353"> {phone} </a>
                                                </li>
                                                <li> <i className="fa-regular fa-address-book text-pink fa-fw"></i> {address}</li>
                                                
                                            </ul>
                                    </div>
                                    <div className="col-md-6 col-12 ">
                                        <div className="w-100">
                                            
                                            <h5 className="mb-3">{businessheading}</h5>
                                            <ul className="mb-4 contact-list list-unstyled">
                                                <li> <i className="fa-regular text-pink fa-check fa-fw"></i> {text1}</li>
                                                <li> <i className="fa-regular text-pink fa-check fa-fw"></i> {text2}</li>
                                                <li> <i className="fa-regular text-pink fa-check fa-fw"></i> {text3}</li>
                                                
                                            </ul>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    )
                }) }
                 
            </div>
        </div>   
        </>
    )
}

export default Homesections