import React, { useState } from "react";
import logo from '../img/ibh.png';
import { NavLink } from "react-router-dom";

const Header = () => {
    const [activeLink, setActiveLink] = useState('home');
    const offset = 70; // Set your offset value here (for example, the height of the fixed navbar)

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = sectionPosition - offset;

            // Scroll to the adjusted position
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
        setActiveLink(sectionId);

        // Close the mobile menu after clicking a link
        const navbarCollapse = document.getElementById('navbarSupportedContent');
        if (navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
        }
    };

    return (
        <nav className="navbar navbar-expand-lg bg-darked position-fixed w-100">
            <div className="container">
                <NavLink onClick={() => scrollToSection('home')} className="navbar-brand fs-1 text-white fw-medium" to="/">
                    <img src={logo} alt="Internet Business Hub" className="" />
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="text-pink fs-1 fa fa-bars" aria-hidden="true"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav text-center ms-auto mb-2 mb-lg-0 text-uppercase">
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${activeLink === 'home' ? 'active' : ''}`} 
                                onClick={() => scrollToSection('home')}
                            >
                                Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${activeLink === 'about' ? 'active' : ''}`} 
                                onClick={() => scrollToSection('about')}
                            >
                                About Us
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${activeLink === 'services' ? 'active' : ''}`} 
                                onClick={() => scrollToSection('services')}
                            >
                                Services
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${activeLink === 'contact' ? 'active' : ''}`} 
                                onClick={() => scrollToSection('contact')}
                            >
                                Contact Us
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;
