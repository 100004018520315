const aboutapi = [
    {
        id: "1",
        subtitle:"Innovative Digital Solutions & Brand Strategy",
        title:"About Us",
        info:"Welcome to Internet Business Hub, where innovation meets excellence. We are dedicated to providing Web Design & Development that make a difference in the lives of our customers. Founded in 2012, we have grown from a small startup to a trusted leader in the industry, known for our commitment to quality and customer satisfaction.",

        headingmission:"Our Mission",
        descmission:"We strive to provide innovative solutions tailored to meet the needs of our clients, ensuring quality and satisfaction in every project we undertake.",
        
        headingvision:"Our Vision",
        descvision:"To be a leader in our industry, constantly evolving and adapting to the changing landscape while maintaining our commitment to sustainability and integrity.",
        
        headingvalue:"Our Values",
        descvalue:"Integrity, innovation, and customer-centricity are at the heart of everything we do. We believe in fostering a culture of trust, creativity, and continuous growth.",
    }
]

export default aboutapi
