import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer"; 
import Homesection from "./components/Homesection";

const Home = () => {
    return(
        <>
            <Header></Header>
            <Homesection></Homesection>
            <Footer></Footer>
        </>
    )
}

export default Home