import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";

const Notfound =  () => {
    return(
        <>
            <Header></Header>
            <div className="text-center container pt-5">
                <div className="col-md-12 pagenotfound mt-5">
                    <h1>Error 404</h1>
                    <h2>Page Not Found</h2>
                    <p>Sorry, the page you're looking for doesn't exist.</p>

                    <NavLink className="btn btn-pink" to="/">Back To Home</NavLink>
                </div>    
            </div>
            <Footer></Footer>
        </>
    )
}

export default Notfound;