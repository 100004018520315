const homeservicesapi = [

    {
        id: "1",
        icon:"fa-regular fa-chart-line fa-fw",
        title:"Marketing",
        info:"Marketing web development creates optimized websites to drive traffic, improve user experience, and boost business conversions.",
        heading: "our services",
    },

    {
        id: "2",
        icon:"fa-sharp fa-regular fa-code fa-fw",
        title:"Development",
        info:"Web development involves building and maintaining websites, focusing on functionality, performance, and user experience across the web.",
    },

    {
        id: "3",
        icon:"fa-sharp fa-regular fa-pen fa-fw",
        title:"Web Design",
        info:"Web design focuses on creating visually appealing, user-friendly website layouts that enhance the overall user experience and interface.",
    },

    {
        id: "4",
        icon:"fa-brands fa-searchengin fa-fw",
        title:"SEO Optimization",
        info:"SEO optimization improves a website's visibility in search engines by enhancing content, keywords, and structure to drive organic traffic.",
    },

    {
        id: "5",
        icon:"fa-sharp fa-regular fa-cart-shopping fa-fw",
        title:"Ecommerce",
        info:"Ecommerce refers to the buying and selling of goods or services online, enabling transactions through websites or mobile applications.",
    },
    {
        id: "6",
        icon:"fa-sharp fa-award fa-fw",
        title:"Branding",
        info:"Branding involves creating a unique identity for a business through visual elements, messaging, and values to build recognition and customer loyalty.",
    },
]

export default homeservicesapi