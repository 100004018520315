import React from "react";

const Footer = () => {
const currenYear = new Date().getFullYear();
    return (
        <>
        <div className="bg-dark">
            <div className="container text-center">
                <div className="col-md-12 pt-3">
                    <p className="text-white mb-0 pb-3"> &copy; {currenYear} Internet Business Hub. All rights reserved.</p>
                </div>
            </div>
        </div>        
        </>
    )
}

export default Footer 